import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './SectionView.scss';
import dataHooks from '../../data-hooks';
import MenuItemView from '../MenuItemView';
import Text from '../../core-components/Text';
import MenuViewEmptyState from '../MenuViewEmptyState';
import { Element } from 'react-scroll';
import { DisplayableSection, MenuDisplayOptions, AlignmentOptions } from '@wix/restaurants-client-logic';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { HeroImage, ImageLoadingBehaviorOptions as LoadingBehaviorOptions } from 'wix-ui-tpa';
import { RouteUrls } from '../../../../core/constants';
import { ClickOnMenuItemPayload } from '../../../../state/cart/cart.actions.types';
import { CARD, SIDE_BY_SIDE } from '../../SharedSettings/Constants';
import stylesParams from '../../stylesParams';
import { openDispatchSettings, openDishModal } from '@wix/bi-logger-olo-client/v2';

interface SectionViewProps {
  displayableSection: DisplayableSection;
  isMobile?: boolean;
  layout?: 'side-by-side' | 'card';
  dishCardAlignment?: AlignmentOptions;
  menuHeaderAlignment?: AlignmentOptions;
  last?: boolean;
  menuDisplayOptions?: MenuDisplayOptions;
  selectAddressOnDishClick?: boolean;
  clickOnMenuItem: (payload: ClickOnMenuItemPayload) => void;
  hasItemImages?: boolean;
}

const SectionView: React.FC<SectionViewProps> = ({
  displayableSection,
  isMobile,
  layout = SIDE_BY_SIDE,
  dishCardAlignment,
  menuHeaderAlignment = AlignmentOptions.EMPTY,
  menuDisplayOptions,
  last,
  selectAddressOnDishClick,
  clickOnMenuItem,
  hasItemImages,
}) => {
  const { t } = useTranslation();
  const hasItems = displayableSection.items.length > 0;
  const image = displayableSection.image;
  const history = useHistory();
  const biLogger = useBi();
  const showSectionImage = !menuDisplayOptions?.hideSectionImages;
  const unique = Math.random();
  const showSectionDescription = !menuDisplayOptions?.hideSectionDescription;

  // Deliberately using an empty string as alt text.
  // In this particular case adding the dish title/description will not have any effect,
  // since this data is shown directly under the image, and we do not want screen reader to read it twice.
  const alt = '';

  const titleId = `section-view-title-${unique}`;
  const style = useStyles();
  const height = style.get(stylesParams.sectionImageHeight);
  const customWidth = isMobile ? 280 : 1280;
  const customHeight = isMobile ? height : 320;

  return (
    <Element name={displayableSection.id} id={`restaurants-orders-entity-${displayableSection.id}`} tabIndex={-1}>
      {image && showSectionImage ? (
        <div data-hook={dataHooks.menusSectionImage} className={styles.imageWrapper}>
          <HeroImage
            key={`${height} ${displayableSection.id}`}
            src={image}
            width={customWidth}
            height={customHeight}
            alt={alt}
            loadingBehavior={LoadingBehaviorOptions.blur}
            fluid
          />
        </div>
      ) : null}
      <Text
        id={titleId}
        className={`${styles.sectionTitle} ${styles[menuHeaderAlignment]}`}
        typography="header-xs"
        tagName="h3"
        fontSizeOverride="section-title"
        data-hook={dataHooks.menusSectionTitle}
      >
        {displayableSection.displayableTitle}
      </Text>
      {showSectionDescription && displayableSection.displayableDescription && (
        <Text
          typography="p2-m"
          tagName="p"
          fontSizeOverride="section-description"
          className={`${styles.description} ${styles[menuHeaderAlignment]}`}
        >
          {displayableSection.displayableDescription}
        </Text>
      )}
      {!hasItems && <MenuViewEmptyState id={displayableSection.id} />}
      {hasItems && (
        <section>
          {/* Even though it might be counter intuitive, using a section
          to wrap a div with role="list" is the correct way to go, a11y-wise,
          as a section has a different semantics than a list */}
          <div
            className={`${styles.sectionGrid} ${layout === CARD && styles.cardLayout} ${last && styles.last}`}
            role="list"
          >
            {displayableSection.items.map((displayableMenuItem) => (
              <MenuItemView
                key={displayableMenuItem.id}
                id={displayableMenuItem.id}
                title={displayableMenuItem.displayableTitle}
                description={displayableMenuItem.displayableDescription}
                minPrice={displayableMenuItem.displayableMinPrice}
                price={displayableMenuItem.displayablePrice}
                maxPrice={displayableMenuItem.displayableMaxPrice}
                labels={displayableMenuItem.labels}
                image={displayableMenuItem.image}
                menuDisplayOptions={menuDisplayOptions}
                discount={undefined}
                error={
                  displayableMenuItem.soldout
                    ? t('online_ordering_menuitem_soldout_label')
                    : !displayableMenuItem.available
                    ? t('online_ordering_menuitem_unavailable_label')
                    : undefined
                }
                onClick={() => {
                  if (selectAddressOnDishClick) {
                    history.push(RouteUrls.DISPATCH_SETTINGS_MODAL);
                    biLogger.report(openDispatchSettings({ openReason: 'click on dish' }));
                  } else {
                    clickOnMenuItem({ displayableMenuItem });
                    biLogger.report(openDishModal({ dishId: displayableMenuItem.id, origin: 'menu' }));
                    history.push(`/dish/${displayableMenuItem.id}`, { originSectionId: displayableSection.id });
                  }
                }}
                isMobile={isMobile}
                className={styles.item}
                layout={layout}
                dishCardAlignment={dishCardAlignment}
                role="listitem"
                currency={displayableMenuItem.currency}
                hasItemImages={hasItemImages}
              />
            ))}
          </div>
        </section>
      )}
    </Element>
  );
};

SectionView.displayName = 'SectionView';

export default SectionView;
