import React, { useEffect } from 'react';
import _ from 'lodash';
import { ButtonPriority as PRIORITY, SectionNotification } from 'wix-ui-tpa';
import { Link, useLocation } from 'react-router-dom';
import {
  Address,
  DispatchInfo,
  DisplayableOrderItem,
  Restaurant,
  VirtualDispatchType,
} from '@wix/restaurants-client-logic';
import styles from './AddressInformationView.scss';
import dataHooks from '../../data-hooks';
import Error from 'wix-ui-icons-common/on-stage/Error';
import AddressInformationSummary from '../AddressInformation/AddressInformationSummary';
import { TextButton, TEXT_BUTTON_PRIORITY } from '../TextButton';
import CheckoutFlowStepTitle from '../CheckoutFlowStepTitle';
import Text from '../../core-components/Text';
import { Trans, useBi, useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import {
  SetDeliveryAddressFieldPayload,
  SetErrorVisibilityPayload,
  SetTpaEstimateInlineErrorPayload,
  ToggleAllErrorsPayload,
} from '../../../../state/addressInformationForm/addressForm.actions.types';
import Button from '../Button';
import { DeliveryFormField } from '../../../../state/addressInformationForm/addressForm.reducer';
import {
  fetchEstimateDeliveryFee,
  getErrorKey,
  getEstimateErrorText,
  InlineError,
  resolveEstimateRequestError,
} from '../AddressInformation/AddressInformation.helper';
import { OpenModalPayload, SaveAddressToServerPayload } from '../../../../state/session/session.actions.types';
import { Modals } from '../../../../core/constants';
import { DineInInfo } from '@wix/restaurants-client-logic/dist/types/types/Restaurant';
import AddressInformationInput from '../AddressInformation/AddressInformationInput';
import { getAsapText } from '../DispatchTimeSelector/DispatchTimeSelector.helper';
import CommentInput from '../CommentInput';
import useMinOrderPriceDetails from '../../../../core/hooks/useMinOrderPriceDetails';
import { convertToOloAddressMembersAddress } from '../../../../core/logic/addressLogic';
import {
  SetDeliveryProviderEstimatePayload,
  SetIsFetchingEstimateFromTPAPayload,
} from '../../../../state/checkout/checkout.actions.types';
import { Element } from 'react-scroll';
import { Address as MembersAddress } from '@wix/ambassador-addresses-web/types';
import { addressInformationContinue, dispatchChangeClick, addressInformationContinueValidationError, pickupShowOnMapClick,  } from '@wix/bi-logger-olo-client/v2';

export interface AddressInformationViewProps {
  restaurant: Restaurant;
  dispatchTime: number;
  dispatchType: VirtualDispatchType;
  done?: boolean;
  collapsed?: boolean;
  index?: string;
  setDeliveryAddressFromForm: () => void;
  setDeliveryAddressAdditionalInfo: () => void;
  onSubmit: () => void;
  onEdit: () => void;
  address: Address;
  formattedAddressWithComment: string;
  setFieldError: (payload: SetErrorVisibilityPayload) => void;
  fieldsErrors: {
    addressLine2: boolean;
  };
  isCurbside: boolean;
  curbsideAdditionalInformation: string | undefined;
  saveStateToSessionStorage: () => void;
  toggleAllErrors: (payload: ToggleAllErrorsPayload) => void;
  selectedAddressOption?: Address;
  idealDeliveryArea?: DispatchInfo;
  errorOrderItem?: DisplayableOrderItem;
  timezone: string;
  errorsVisibility: Record<DeliveryFormField, boolean>;
  deliveryInfos: DispatchInfo[];
  describedby?: string;
  locale: string;
  openModal: (payload: OpenModalPayload) => void;
  saveAddressToServer: (address: SaveAddressToServerPayload) => void;
  initialTableNumber?: string;
  dineInInfo?: DineInInfo;
  setDeliveryAddressField: (payload: SetDeliveryAddressFieldPayload) => void;
  isMultiLocation: boolean;
  selectedAddressId?: string;
  subtotal: number;
  setDeliveryProviderEstimate: (payload: SetDeliveryProviderEstimatePayload) => void;
  setIsFetchingEstimateFromTPA: (payload: SetIsFetchingEstimateFromTPAPayload) => void;
  removeDeliveryProviderFromState: () => void;
  isFetchingEstimateFromTPA?: boolean;
  signedInstance: string;
  tpaEstimateInlineError: InlineError | undefined;
  setTpaEstimateInlineError: (payload: SetTpaEstimateInlineErrorPayload) => void;
  isUserLoggedIn?: boolean;
  savedAddresses?: MembersAddress[];
  hasOnlinePayment: boolean;
  orderPacingLevel?: number;
}

const AddressInformationView: React.FC<AddressInformationViewProps> = ({
  dispatchType,
  index = '0',
  done,
  collapsed,
  onEdit,
  address,
  onSubmit,
  restaurant,
  dispatchTime,
  setDeliveryAddressFromForm,
  setDeliveryAddressAdditionalInfo,
  isCurbside,
  curbsideAdditionalInformation,
  formattedAddressWithComment,
  toggleAllErrors,
  selectedAddressOption,
  initialTableNumber,
  fieldsErrors,
  setFieldError,
  idealDeliveryArea,
  errorOrderItem,
  timezone,
  errorsVisibility,
  deliveryInfos,
  describedby,
  locale,
  openModal,
  dineInInfo,
  setDeliveryAddressField,
  saveStateToSessionStorage,
  isMultiLocation,
  saveAddressToServer,
  selectedAddressId,
  subtotal,
  setIsFetchingEstimateFromTPA,
  setDeliveryProviderEstimate,
  signedInstance,
  tpaEstimateInlineError,
  setTpaEstimateInlineError,
  removeDeliveryProviderFromState,
  isUserLoggedIn,
  savedAddresses,
  hasOnlinePayment,
  orderPacingLevel,
}) => {
  const experiments = useExperiments();
  const location = useLocation();
  const { t } = useTranslation();
  const biLogger = useBi();
  const { displayableAmountLeft, isMinOrderPriceMet } = useMinOrderPriceDetails();

  useEffect(() => {
    toggleAllErrors({ value: false });
  }, [location, toggleAllErrors]);

  const timing = dispatchTime
    ? new Intl.DateTimeFormat(locale.split('_')[0], {
        day: 'numeric',
        month: 'long',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timezone,
      }).format(dispatchTime)
    : getAsapText({ deliveryInfos, timezone, dispatchType, t, idealDeliveryArea, locale, orderPacingLevel });

  const idSuffix = Math.random();
  const addressInformationTitleId = `restaurants.address-information.title-${idSuffix}`;

  function handleSubmitTakeout() {}

  function handleSubmitDineIn() {}

  async function handleSubmitDelivery() {
    const isAptValid =
      Boolean(selectedAddressOption?.addressLine2) ||
      !experiments.experiments.enabled('specs.restaurants.AptFieldIsRequiredInDelivery');

    toggleAllErrors({ value: true });
    const addressToValidate = selectedAddressOption;
    const configurationId = _.get(idealDeliveryArea, 'deliveryProviderInfo.configurationId', undefined);
    if (configurationId && addressToValidate) {
      const { errors, fee, estimateId } = await fetchEstimateDeliveryFee({
        addressToValidate,
        subtotal,
        currency: restaurant.currency,
        idealDeliveryArea,
        configurationId,
        setIsFetchingEstimateFromTPA,
        signedInstance,
        localDispatchTime: dispatchTime,
        experiments: experiments.experiments,
        hasOnlinePayment,
        biLogger,
        stage: 'checkout-summary',
        locationGuid: restaurant.currentLocationId,
      });
      const estimateTpaHasError = errors && errors.length > 0;
      if (estimateTpaHasError) {
        const estimateError = resolveEstimateRequestError(t, 'our partner', 'checkout', errors![0]);
        if (estimateError.modalError) {
          openModal({ modal: estimateError.modalError });
        }
        if (estimateError.addressError || estimateError.inlineError) {
          setTpaEstimateInlineError({ value: estimateError.inlineError || 'time' });
        }
        return 'error';
      }
      if (!estimateTpaHasError && fee !== undefined && estimateId) {
        setDeliveryProviderEstimate({ estimateId, fee, configurationId });
      }
    }
    if (!configurationId) {
      removeDeliveryProviderFromState();
    }
    if (!isAptValid) {
      setFieldErrorAndBi({ errorType: 'addressLine2', configurationId });
      return 'error';
    }

    return 'ok';
  }

  async function handleSubmit() {
    switch (dispatchType) {
      case 'takeout':
        handleSubmitTakeout();
        break;
      case 'delivery':
        const result = await handleSubmitDelivery();
        if (result === 'error') {
          return;
        }
        if (selectedAddressOption && selectedAddressId) {
          const isAddressChanged =
            address.label !== selectedAddressOption.label ||
            address.comment !== selectedAddressOption.comment ||
            address.addressLine2 !== selectedAddressOption.addressLine2;

          isAddressChanged &&
            saveAddressToServer({
              address: convertToOloAddressMembersAddress(selectedAddressOption),
              addressId: selectedAddressId,
              setAsDefault: false,
            });
        }
        break;
      case 'dine-in':
        handleSubmitDineIn();
        break;
      default:
        return;
    }

    if (!isMultiLocation) {
      setDeliveryAddressFromForm();
    } else {
      setDeliveryAddressAdditionalInfo();
    }

    onSubmit();
    toggleAllErrors({ value: false });
    saveStateToSessionStorage();
    biLogger.report(
      addressInformationContinue({
        locationGuid: restaurant.currentLocationId,
        comment: selectedAddressOption?.comment || '',
        dispatchTime,
        dispatchTimeOption: dispatchTime ? 'later' : 'asap',
        dispatchType,
        curbsidePickupToggle: isCurbside,
        curbsideAdditionalInformationContent: curbsideAdditionalInformation,
      }),
    );
  }

  function setFieldErrorAndBi({
    errorType,
    configurationId,
  }: {
    errorType: DeliveryFormField;
    configurationId?: string;
  }) {
    setFieldError({ error: errorType, value: true });
    biLogger.report(
      addressInformationContinueValidationError({
        locationGuid: restaurant.currentLocationId,
        comment: selectedAddressOption?.comment || '',
        dispatchTime,
        dispatchTimeOption: dispatchTime ? 'later' : 'asap',
        dispatchType,
        errorReason: errorType as string,
        configurationId,
        pageName: 'checkout',
      }),
    );
  }

  function shouldRenderAddressLabelField() {
    const hasSavedAddresses = !_.isEmpty(savedAddresses);

    return isUserLoggedIn && hasSavedAddresses;
  }

  function renderDelivery() {
    return (
      <React.Fragment>
        <div className={styles.field}>
          <Text tagName="label" typography="p2-s" className={styles.label}>
            {t('checkout_main_delivery_address')}
          </Text>
          <Text tagName="p" typography="p2-m" data-hook={dataHooks.addressInformationViewAddress}>
            {address.formatted}
          </Text>
        </div>

        {selectedAddressOption && (
          <>
            <div className={styles.field}>
              <AddressInformationInput
                address={selectedAddressOption}
                field="addressLine2"
                onChange={setDeliveryAddressField}
                label={t('checkout_main_delivery_aptfloor.label')}
                data-hook={dataHooks.addressInformationLine2}
                className={styles.additionalAddressInformation}
                placeholder={t('checkout_main_deliverymethod_modal.addaddress.addinfo.placeholdertext')}
                errorMessage={
                  errorsVisibility.addressLine2 && fieldsErrors.addressLine2
                    ? t('checkout_main_delivery_contactinfo_mandatoryfield_errormessage')
                    : ''
                }
              />
            </div>
            {shouldRenderAddressLabelField() && (
              <div className={styles.field}>
                <AddressInformationInput
                  address={selectedAddressOption}
                  field="label"
                  onChange={setDeliveryAddressField}
                  label={t('checkout_main_deliverymethod_modal.addaddress.label.title')}
                  data-hook={dataHooks.addressInformationLabelField}
                  placeholder={t('checkout_main_deliverymethod_modal.addaddress.label.placeholdertext')}
                />
              </div>
            )}
          </>
        )}
        <div className={styles.field}>
          <label>
            <CommentInput
              onChange={(newComment: string) => setDeliveryAddressField({ addressField: 'comment', value: newComment })}
              value={selectedAddressOption?.comment || ''}
              content={t('checkout_main_instructions_link')}
              dataHook={dataHooks.addressInformationComment}
              textClassName={styles.addressInformation_textArea}
              buttonClassName={styles.commentButton}
            />
          </label>
        </div>
      </React.Fragment>
    );
  }

  function renderTakeout() {
    return (
      <React.Fragment>
        <div className={styles.field}>
          <Text tagName="label" typography="p2-s" className={styles.label}>
            {isCurbside ? t('checkout_main_pickup_location_curbside') : t('checkout_main_pickup_location')}
          </Text>
          <div className={styles.pickupAddress}>
            <Text tagName="p" typography="p2-s" data-hook={dataHooks.addressInformationViewPickupLocation}>
              {formattedAddressWithComment}
            </Text>
            <TextButton
              priority={TEXT_BUTTON_PRIORITY.link}
              onClick={() => {
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(restaurant.address.formatted)}`,
                );
                biLogger.report(
                  pickupShowOnMapClick({
                  locationGuid: restaurant.currentLocationId,
                  pageName: 'checkout',
                }));
              }}
              data-hook={dataHooks.addressInformationShowMap}
              className={styles.showOnMapLink}
            >
              {t('order_settings_modal_showmap_cta')}
            </TextButton>
          </div>
        </div>
        {isCurbside && curbsideAdditionalInformation && (
          <div className={styles.field}>
            <Text tagName="label" typography="p2-s" className={styles.label}>
              Outfit Description
            </Text>
            <div className={styles.pickupAddress}>
              <Text tagName="p" typography="p2-s" data-hook={dataHooks.addressInformationViewCurbsideOutfitInfo}>
                {curbsideAdditionalInformation}
              </Text>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  function renderDineIn() {
    return (
      <React.Fragment>
        <div className={styles.field}>
          <Text tagName="label" typography="p2-s" className={styles.label}>
            {t('Checkout_main_deliverymethod_DineIn_Location_Text')}
          </Text>
          <div className={styles.pickupAddress}>
            <Text tagName="p" typography="p2-m" data-hook={dataHooks.addressInformationViewDineInLocation}>
              {formattedAddressWithComment}
            </Text>
          </div>
        </div>
        <div className={styles.field}>
          <div className={styles.pickupAddress}>
            <Text tagName="p" typography="p2-m" data-hook={dataHooks.addressInformationViewDineInInstructions}>
              {dineInInfo?.instructions}
            </Text>
          </div>
        </div>
        <div>
          <Text tagName="label" typography="p2-s" className={styles.label}>
            {dineInInfo?.label}
          </Text>
          <div className={styles.pickupAddress}>
            <Text tagName="p" typography="p2-m" data-hook={dataHooks.addressInformationViewDineInLabelValue}>
              {initialTableNumber}
            </Text>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <Element name={'address-information'}>
      <div
        data-hook={dataHooks.addressInformationView}
        className={styles.wrapper}
        aria-labelledby={addressInformationTitleId}
        aria-describedby={describedby}
      >
        <CheckoutFlowStepTitle
          text={t('checkout_main_delivery_method')}
          done={done}
          collapsed={collapsed}
          index={index}
          onEdit={onEdit}
          action={{
            onAction: () => {
              biLogger.report(dispatchChangeClick({ pageName: 'checkout' }));
              openModal({
                modal: Modals.ADDRESS_INFORMATION_MODAL,
                context: {
                  origin: 'checkout',
                },
              });
            },
            label: t('checkout_main_delivery_method_change.cta'),
            dataHook: dataHooks.addressInformationChangeAction,
          }}
          editButtonDataHook={dataHooks.checkoutSummaryLineEditAddress}
          titleId={addressInformationTitleId}
        />

        {!done && !collapsed && (
          <form
            onSubmit={(e: React.FormEvent) => {
              e.preventDefault();
              handleSubmit();
            }}
            data-hook={dataHooks.addressInformationForm}
          >
            {dispatchType === 'delivery' && renderDelivery()}
            {dispatchType === 'takeout' && renderTakeout()}
            {dispatchType === 'dine-in' && renderDineIn()}

            {dispatchType !== 'dine-in' && (
              <React.Fragment>
                <Text tagName="label" typography="p2-s" className={styles.label}>
                  {dispatchType === 'delivery' ? t('checkout_main_delivery_time') : t('checkout_main_pickup_time')}
                </Text>
                <Text tagName="p" typography="p2-m" data-hook={dataHooks.addressInformationViewTimeLine}>
                  {timing}
                </Text>
              </React.Fragment>
            )}
            {errorOrderItem && getErrorKey(errorOrderItem) && (
              <SectionNotification type="error" className={styles.error} data-hook={dataHooks.addressInformationError}>
                <SectionNotification.Icon icon={<Error />} />
                <SectionNotification.Text>
                  <Trans
                    t={t}
                    i18nKey={getErrorKey(errorOrderItem)}
                    components={[
                      <Link data-hook={dataHooks.addressInformationErrorLink} to="/cart">
                        placeholder
                      </Link>,
                    ]}
                  />
                </SectionNotification.Text>
              </SectionNotification>
            )}

            {!isMinOrderPriceMet && (
              <SectionNotification
                type="error"
                className={styles.error}
                data-hook={dataHooks.addressInformationMinOrderPriceErrorBanner}
              >
                <SectionNotification.Icon icon={<Error />} />
                <SectionNotification.Text>
                  <Trans
                    t={t}
                    i18nKey={'checkout_main_order_minprice_errormessage_with_link'}
                    components={{
                      1: (
                        <Link data-hook={dataHooks.addressInformationMinOrderPriceErrorBannerLink} to="/">
                          placeholder
                        </Link>
                      ),
                    }}
                    values={{ amount: displayableAmountLeft }}
                  />
                </SectionNotification.Text>
              </SectionNotification>
            )}
            {tpaEstimateInlineError && dispatchType === 'delivery' && (
              <SectionNotification
                type="error"
                className={styles.error}
                data-hook={dataHooks.addressInformationTpaInlineErrorBanner}
              >
                <SectionNotification.Icon icon={<Error />} />
                <SectionNotification.Text>
                  {getEstimateErrorText({ tpaEstimateInlineError, t })}
                </SectionNotification.Text>
              </SectionNotification>
            )}
            <Button
              upgrade
              fullWidth
              priority={PRIORITY.primary}
              className={styles.button}
              data-hook={dataHooks.addressInformationContinue}
              type="submit"
              disabled={!!errorOrderItem || !isMinOrderPriceMet || !!tpaEstimateInlineError}
            >
              <Text typography="p2-m-colorless">{t('checkout_main_button_continue')}</Text>
            </Button>
          </form>
        )}

        {done && (
          <AddressInformationSummary
            dineInInfo={dineInInfo}
            address={dispatchType === 'delivery' ? address : restaurant.address}
            dispatchType={dispatchType}
            dispatchTime={dispatchTime}
            timezone={timezone}
            locale={locale}
            tableNumber={initialTableNumber}
            deliveryInfos={deliveryInfos}
            idealDeliveryArea={idealDeliveryArea}
            isCurbsideToggledOn={isCurbside}
            orderPacingLevel={orderPacingLevel}
          />
        )}
      </div>
    </Element>
  );
};

AddressInformationView.displayName = 'AddressInformationView';

export default AddressInformationView;
