import React, { useCallback, useState } from 'react';
import ModalLayoutBasic from '../ModalLayoutBasic';
import Text from '../../core-components/Text';
import Button from '../Button';
import { ButtonPriority as PRIORITY } from 'wix-ui-tpa';
import { TextButton } from '../TextButton';
import styles from './SmsModal.scss';
import dataHooks from '../../data-hooks';
import TextField from '../TextField';
import { useTranslation, useBi } from '@wix/yoshi-flow-editor';
import { smsValidationCancel } from '@wix/bi-logger-olo-client/v2';

export interface SmsModalProps {
  phoneNumber: string;
  onRequestClose: () => void;
  onSubmit: (code: string) => void;
  hasSmsError?: boolean;
  loading?: boolean;
  abortSmsVerification: Function;
}

const SmsModal: React.FC<SmsModalProps> = ({
  phoneNumber,
  onRequestClose,
  onSubmit,
  hasSmsError,
  loading,
  abortSmsVerification,
}) => {
  const biLogger = useBi();
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const handleCancel = useCallback(
    (origin: string) => {
      onRequestClose();
      biLogger.report(smsValidationCancel({ origin }));
      abortSmsVerification();
    },
    [onRequestClose, abortSmsVerification, biLogger],
  );

  const header = (
    <Text typography="header-s" className={styles.header}>
      {t('checkout_confirm_modal_title')}
    </Text>
  );
  const subtitle = t('checkout_confirm_modal_subtitle', { number: phoneNumber });
  const placeholder = t('checkout_confirm_modal_placeholder');
  const comment = t('checkout_confirm_modal_no_sms');
  const cancel = t('checkout_confirm_modal_back_link');
  const cta = t('checkout_confirm_modal_CTA');
  const error = 'This code does not match, try again.';

  return (
    <ModalLayoutBasic header={header} onCloseClick={() => handleCancel('x/outside')} data-hook={dataHooks.smsModal}>
      <Text typography="p2-m" className={styles.subtitle}>
        {subtitle}
      </Text>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(code);
        }}
      >
        <div className={styles.inputWrapper}>
          <TextField
            placeholder={placeholder}
            value={code}
            onChange={(e) => setCode(e.target.value)}
            data-hook={dataHooks.smsModalInput}
            error={hasSmsError}
            errorMessage={hasSmsError ? error : ''}
            disabled={loading}
            maxLength={15}
          />
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            priority={PRIORITY.primary}
            upgrade
            type="submit"
            data-hook={dataHooks.smsModalInputConfirm}
            loading={loading}
          >
            {cta}
          </Button>
        </div>
      </form>
      <Text typography="p2-s" className={styles.comment}>
        {comment}
      </Text>
      <div className={styles.buttonWrapper}>
        <TextButton onClick={() => handleCancel('cancel')} data-hook={dataHooks.smsModalInputCancel}>
          {cancel}
        </TextButton>
      </div>
    </ModalLayoutBasic>
  );
};

SmsModal.displayName = 'SmsModal';

export default SmsModal;
