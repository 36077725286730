import React, { useState, SyntheticEvent, useEffect } from 'react';
import { scroller, Element } from 'react-scroll';
import { Spinner, ButtonPriority as PRIORITY } from 'wix-ui-tpa';
import { SetContactFieldPayload, SetContactPayload } from '../../../../state/checkout/checkout.actions.types';
import styles from './ContactInformation.scss';
import Text from '../../core-components/Text';
import usePhoneUtils from '../../../../core/hooks/usePhoneUtils';
import dataHooks from '../../data-hooks';
import ContactInformationInput from './ContactInformationInput';
import CheckoutFlowStepTitle from '../CheckoutFlowStepTitle';
import { EmailValidator } from 'commons-validator-js';
import { useBi, useExperiments, TFunction, useTranslation } from '@wix/yoshi-flow-editor';
import Button from '../Button';
import Scroller from '../Scroller';
import { Contact, EMPTY_CONTACT } from '@wix/restaurants-client-logic';
import ContactPhoneNumberInput from './ContactPhoneNumberInput';
import { getScrollOptions } from '../CheckoutFlow/CheckoutFlow';
import { contactInformationContinue, contactInformationContinueValidationError } from '@wix/bi-logger-olo-client/v2';

export interface ContactInformationProps {
  initialContact?: Contact;
  countryCode?: string;
  setContact: (payload: SetContactPayload) => void;
  done?: boolean;
  collapsed?: boolean;
  forceErrorVisibility?: boolean;
  index?: string;
  onSubmit: () => void;
  onEdit: () => void;
  saveStateToSessionStorage: () => void;
  describedby?: string;
  isUserLoggedIn: boolean;
  isMAInstalled?: boolean;
  hasMembersAreaIntegration: boolean;
  siteIsTemplate?: boolean;
  isMobile?: boolean;
}

function validateString(str: any, field: string, t: TFunction) {
  return str
    ? ''
    : field === 'firstName'
    ? t('checkout_main_delivery_contactinfo_firstname_errormessage')
    : t('checkout_main_delivery_contactinfo_lastname_errormessage');
}

function validateEmail(email: string, t: TFunction) {
  const isValid = new EmailValidator().isValid(email);
  return isValid ? '' : t('checkout_main_delivery_contactinfo_email_errormessage');
}

function validatePhone(PhoneUtils: any, phoneNumber: string, countryCode: string, t: TFunction) {
  let errorMessage = '';
  if (!countryCode) {
    errorMessage = 'The restaurant address is invalid';
  }
  if (!PhoneUtils.validatePhone(phoneNumber, countryCode)) {
    errorMessage = t('checkout_main_delivery_contactinfo_phone_errormessage');
  }
  return errorMessage;
}

const ContactInformation: React.FC<ContactInformationProps> = ({
  initialContact = EMPTY_CONTACT,
  countryCode,
  setContact,
  collapsed,
  done,
  index = '',
  onSubmit,
  forceErrorVisibility,
  onEdit,
  saveStateToSessionStorage,
  hasMembersAreaIntegration,
  describedby,
  isUserLoggedIn,
  isMAInstalled,
  siteIsTemplate,
  isMobile,
}) => {
  const biLogger = useBi();

  const { t } = useTranslation();

  const PhoneUtils = usePhoneUtils();

  const [localContact, setLocalContact] = useState(initialContact);

  const [isWaiting, setIsWaiting] = useState(false);

  const [validations, setValidations] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const [errorVisibility, setErrorVisibility] = useState({
    firstName: Boolean(forceErrorVisibility),
    lastName: Boolean(forceErrorVisibility),
    email: Boolean(forceErrorVisibility),
    phone: Boolean(forceErrorVisibility),
  });
  const { experiments } = useExperiments();
  const templatesDemoExperimentEnabled = experiments.enabled('specs.restaurants.templatesDemo');
  const isTemplate = siteIsTemplate && templatesDemoExperimentEnabled;
  useEffect(() => {
    if (errorVisibility.firstName && validations.firstName) {
      scroller.scrollTo('firstName', getScrollOptions());
    } else if (errorVisibility.lastName && validations.lastName) {
      scroller.scrollTo('lastName', getScrollOptions());
    } else if (errorVisibility.phone && validations.phone) {
      scroller.scrollTo('phone', getScrollOptions());
    } else if (errorVisibility.email && validations.email) {
      scroller.scrollTo('email', getScrollOptions());
    }
  }, [
    validations.firstName,
    validations.lastName,
    validations.phone,
    validations.email,
    errorVisibility.firstName,
    errorVisibility.lastName,
    errorVisibility.phone,
    errorVisibility.email,
  ]);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setIsWaiting(true);
    setErrorVisibility({
      firstName: true,
      lastName: true,
      email: true,
      phone: true,
    });

    const newValidations = {
      firstName: validateString(localContact.firstName, 'firstName', t),
      lastName: validateString(localContact.lastName, 'lastName', t),
      email: validateEmail(localContact.email, t),
      phone: !isTemplate ? validatePhone(PhoneUtils, localContact.phone, countryCode || '', t) : '',
    };
    setValidations(newValidations);
    if (Object.values(newValidations).every((v) => !v)) {
      const emailDomain = localContact.email.substring(localContact.email.indexOf('@') + 1);
      biLogger.report(contactInformationContinue({ emailDomain }));

      setContact({ contact: localContact });
      onSubmit();
      saveStateToSessionStorage();
    } else {
      const errors = Object.values(newValidations)
        .filter((v) => v)
        .join();
      biLogger.report(contactInformationContinueValidationError({ errorReason: errors }));

      // focus on input that has error after trying to submit
      let element;
      if (newValidations.firstName) {
        element = document.getElementById(dataHooks.checkoutFlowFirstNameInput);
      } else if (newValidations.lastName) {
        element = document.getElementById(dataHooks.checkoutFlowLastNameInput);
      } else if (newValidations.phone) {
        element = document.getElementById(dataHooks.checkoutFlowPhoneInput);
      } else if (newValidations.email) {
        element = document.getElementById(dataHooks.checkoutFlowEmailInput);
      }

      if (element) {
        element.focus();
      }
    }
    setIsWaiting(false);
  };

  const getErrorMessage = (field: keyof typeof validations) => {
    return errorVisibility[field] ? validations[field] : '';
  };

  const handleInputChange = ({ field, value }: SetContactFieldPayload) => {
    setErrorVisibility((v) => ({ ...v, [field]: false }));
    setLocalContact({ ...localContact, [field]: value });
  };

  const titleId = `${dataHooks.contactInformation}-title`;
  const shouldDisableEmailInput = hasMembersAreaIntegration && isMAInstalled && isUserLoggedIn;
  const isInitialContact = () => initialContact?.lastName || initialContact?.firstName || initialContact?.phone;
  return (
    <Scroller name="contact-information" condition={isMobile ? !collapsed && !done : Boolean(done)}>
      <div
        data-hook={dataHooks.contactInformation}
        aria-labelledby={titleId}
        className={styles.wrapper}
        aria-describedby={describedby}
      >
        <CheckoutFlowStepTitle
          text={t('checkout_main_contactinfo_title')}
          done={done}
          collapsed={collapsed}
          index={index}
          onEdit={onEdit}
          editButtonDataHook={dataHooks.checkoutSummaryLineEditContact}
          titleId={titleId}
        />

        {!collapsed && !done && !PhoneUtils && <Spinner />}

        {!collapsed && !done && PhoneUtils && (
          <form noValidate className={styles.form} onSubmit={handleSubmit} data-hook={dataHooks.contactInformationForm}>
            <div className={styles.inputs}>
              <Element name={'firstName'}>
                <ContactInformationInput
                  contact={localContact}
                  field="firstName"
                  label={t('checkout_main_contactinfo_firstname_title')}
                  data-hook={dataHooks.checkoutFlowFirstNameInput}
                  onChange={handleInputChange}
                  errorMessage={getErrorMessage('firstName')}
                  autoFocus
                  id={dataHooks.checkoutFlowFirstNameInput}
                />
              </Element>
              <Element name={'lastName'}>
                <ContactInformationInput
                  contact={localContact}
                  field="lastName"
                  label={t('checkout_main_contactinfo_lastname_title')}
                  data-hook={dataHooks.checkoutFlowLastNameInput}
                  onChange={handleInputChange}
                  errorMessage={getErrorMessage('lastName')}
                  id={dataHooks.checkoutFlowLastNameInput}
                />
              </Element>
              <Element name={'phone'}>
                <ContactPhoneNumberInput
                  type="tel"
                  contact={localContact}
                  field="phone"
                  label={t('checkout_main_contactinfo_phone_title')}
                  data-hook={dataHooks.checkoutFlowPhoneInput}
                  onChange={handleInputChange}
                  errorMessage={getErrorMessage('phone')}
                  id={dataHooks.checkoutFlowPhoneInput}
                />
              </Element>
              <Element name={'email'}>
                <ContactInformationInput
                  disable={shouldDisableEmailInput}
                  type="email"
                  contact={localContact}
                  field="email"
                  label={t('checkout_main_contactinfo_email_title')}
                  data-hook={dataHooks.checkoutFlowEmailInput}
                  onChange={handleInputChange}
                  errorMessage={getErrorMessage('email')}
                  id={dataHooks.checkoutFlowEmailInput}
                />
              </Element>
            </div>
            <Button
              upgrade
              fullWidth
              priority={PRIORITY.primary}
              className={styles.button}
              data-hook={dataHooks.contactInformationContinue}
              type="submit"
              disabled={isWaiting}
            >
              <Text typography="p2-m-colorless">{t('checkout_main_button_continue')}</Text>
            </Button>
          </form>
        )}

        {done && (
          <div className={styles.summaryWrapper}>
            <Text
              typography="p2-m-secondary"
              className={styles.summaryLine}
              data-hook={dataHooks.contactInformationSummaryLine}
            >
              {localContact.firstName} {localContact.lastName}
            </Text>
            <Text
              typography="p2-m-secondary"
              className={styles.summaryLine}
              data-hook={dataHooks.contactInformationSummaryLine}
            >
              {localContact.phone}
            </Text>
            <Text
              typography="p2-m-secondary"
              className={styles.summaryLine}
              data-hook={dataHooks.contactInformationSummaryLine}
            >
              {localContact.email}
            </Text>
          </div>
        )}
      </div>
    </Scroller>
  );
};

ContactInformation.displayName = 'ContactInformation';

export default ContactInformation;
